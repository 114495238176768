<template>
 
  
  <div class="page">

    <UiInput
      :label="'Email'"
      :text="email"
      :name="'email'"
      @change:text="email = $event"
      />


    <NuxtTurnstile  ref="turnstile" v-model="token" />

    <div class="actions"> 
      <UiButton
        :name="'Get password reset email'"
        :type="'primary'"
        @click="login"
        :is-loading="isLoding"
        />        
      <UiButton
        :name="'Cancel'"
        :tag="'NuxtLink'"
        :type="'link'"
        to="/auth/signin"
        />          
    </div>  
 
    
  </div>  

</template>

<script setup>

definePageMeta({
  layout: "short",
  auth: false
})

const email = ref('')
const router = useRouter();
const { $user, $popup, $api } = useNuxtApp();
const isLoding = ref(false)
const token = ref(null)
const turnstile = ref()

// recaptcha
// import { useReCaptcha } from 'vue-recaptcha-v3';
// const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()
// const recaptchaInstance = useReCaptcha();
// const execute = async function() {
//   await recaptchaInstance?.recaptchaLoaded();
//   const token = await recaptchaInstance?.executeRecaptcha('signin');
//   return token
// }
// 

const validateEmail = (val) => {
  const re = /\S+@\S+\.\S+/
  return re.test(val)
}

const login = async () => {

  if (!validateEmail(email.value)) {
    $popup.error('Invalid email address')
    return
  }


  // var recaptcha = await execute()

  isLoding.value = true;
  $api
    .postForgotPasswordRequest(email.value, token.value)
    .then(() => {
      email.value = '';
      isLoding.value = false;
      $popup.success('Check your email for the password reset link')
      // router.push('/')
    })
    .catch((err) => {
      turnstile.value?.reset();
      isLoding.value = false;
      $popup.error(err.message)
    })
}


</script>


<style scoped>
.page{
  padding: 2rem;
} 
.actions{
  /* margin-top: 10px; */
}
.actions > *{
  margin-right: 10px;
}
</style>
